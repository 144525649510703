import { useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins';
import parse from 'html-react-parser';
import '../styles/Editor.scss'

import { toBraille } from '../utils/brailleConversor';
import { ExportModal } from './ExportModal';
import { ImportModal } from './ImportModal';

const bastardillaNodeNames = ['STRONG', 'EM', 'DEL', 'U'];

const Editor = () => {
  const [editorState, setEditorState] = useState('');
  const editor = useRef();
  const exportModal = useRef();
  const importModal = useRef();

  const getSunEditorInstance = (sunEditor) => editor.current = sunEditor;
  const getSunEditorContent = () => editor.current.getContents();
  const setSunEditorContent = (value) => editor.current.setContents(value);

  const handleChange = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const bodyElement = doc.body;
    modifyTextNodes(bodyElement);
    const modifiedBodyHtmlString = bodyElement.innerHTML;
    setEditorState(modifiedBodyHtmlString)
  }

  const modifyTextNodes = (node, bastardillaFlag = false) => {
    const shouldAddBastardilla = bastardillaNodeNames.includes(node.nodeName) || bastardillaFlag;
    if (node.nodeType === Node.TEXT_NODE) {
      node.nodeValue = toBraille(shouldAddBastardilla ? `☺${node.nodeValue}☺` : node.nodeValue);
    } else {
      node.childNodes.forEach(childNode => modifyTextNodes(childNode, shouldAddBastardilla));
    }
  }

  const exportContentPlugin = {
    name: 'exportContent',
    display: 'command',
    title: 'Exportar',
    innerHTML: '<i class="fas fa-download"></i>',
    add: function (core, targetElement) {
      const context = core.context;
      context.exportContent = {
        targetButton: targetElement,
      };
    },
    action: function () {
      exportModal.current.open();
    }
  };

  const importContentPlugin = {
    name: 'importContent',
    display: 'command',
    title: 'Importar',
    innerHTML: '<i class="fas fa-upload"></i>',
    add: function (core, targetElement) {
      const context = core.context;
      context.exportContent = {
        targetButton: targetElement,
      };
    },
    action: function () {
      importModal.current.open();
    }
  };

  return (
    <div className='columns is-layout-container'>
      <div className='column sun-editor' id='toolbar_container' />
      <div className='column is-editor-wrapper'>
        <SunEditor
          lang='es'
          getSunEditorInstance={getSunEditorInstance}
          onChange={handleChange}
          setDefaultStyle='font-size: 16px;'
          autoFocus={true}
          height='100%'
          setOptions={{
            plugins: [...Object.values(plugins), exportContentPlugin, importContentPlugin],
            minHeight: '400px',
            toolbarContainer: '#toolbar_container',
            buttonList: [[
              'fontSize',
              'formatBlock',
              'undo', 'redo',
              'bold', 'underline',
              'italic', 'strike',
              'subscript', 'superscript',
              'fontColor', 'hiliteColor',
              'indent', 'outdent',
              'list', 'align',
              'lineHeight', 'removeFormat',
              'importContent', 'exportContent',
            ]],
          }}
        />
      </div>
      <div className='column is-reader-wrapper'>
        <div className='reader'>
          {parse(editorState)}
        </div>
      </div>
      <ExportModal ref={exportModal} getSunEditorContent={getSunEditorContent} />
      <ImportModal ref={importModal} setSunEditorContent={setSunEditorContent} />
    </div>
  );
}

export { Editor };
