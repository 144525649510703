import characterTranslator from './spanishCharTranslator';

const unicodeBase = 0x2800;
const numberFollows = 0b111100;
const capitalFollows = 0b101000;
const unknown = 0b111111;
const letterIndicator = 0b000110;
const bastardilla = 0b010100;

let brailleTranslationMode;

function isInRange(character, bottom, top) {
  return bottom <= character && character <= top;
}

function countRangeWithMax(text, bottom, top, max) {
  let count = 0;
  for (let i = 0; i < text.length; i++) {
    let character = text[i];
    if (isInRange(character, bottom, top)) {
      if (++count === max) {
        return count;
      }
    }
  }
  return count;
}

function binaryToBraille(binary) {
  return String.fromCharCode(unicodeBase + binary);
}

function toBrailleWord(word) {
  const result = [];

  // is the whole word uppercase and contains at least 2 upper case letters?
  if (word === word.toUpperCase() && countRangeWithMax(word, 'A', 'Z', 2) >= 2) {
    result.push(capitalFollows);
    result.push(capitalFollows);
    word = word.toLowerCase();
  }

  let numberMode = false;

  // loop through the word
  for (let i = 0; i < word.length; i++) {
    let character = word[i];

    // check to see if we should be in number mode or letter mode
    if (isInRange(character, '0', '9')) {
      if (!numberMode) {
        // when switching to number mode, output the number follows character
        result.push(numberFollows);
        numberMode = true;
      }
    } else if (isInRange(character, 'a', 'z') || isInRange(character, 'A', 'Z')) {
      if (numberMode) {
        // when switching out of number mode, output the letter indicator character
        result.push(letterIndicator);
        numberMode = false;
      }
    }

    // if the character is uppercase, then lowercase it and mark it as a capital letter
    if (isInRange(character, 'A', 'Z')) {
      result.push(capitalFollows);
      character = character.toLowerCase();
    }

    // get the translation, and add it to the result
    // if no translation, fall back on the "unknown" character
    const translation = characterTranslator[character] || [unknown];
    for (let j = 0; j < translation.length; j++) {
      result.push(translation[j]);
    }

    if (brailleTranslationMode === 'translateWithOriginal') {
      result.push(word[i]);
    }
  }

  // finally, convert the array of binary numbers to a string
  return result.map(function (binary) {
    return (typeof binary === 'string') ? binary : binaryToBraille(binary);
  }).join('');
};

function toBraille(text) {
  if (!text) return '';
  let addBastardilla = false;
  const formattedBastardilla = binaryToBraille(bastardilla);

  // if ☺ is present at begining and end it should be changed by bastardilla
  if (text.startsWith('☺') && text.endsWith('☺')) {
    text = text.replace(/^☺|☺$/g, '');
    addBastardilla = true;
  }

  // convert each word individually
  const result = text.split(/(\s+)/).map(function (word, i) {
    if ((i % 2) === 0) return toBrailleWord(word);
    if (word === ' ') return binaryToBraille(0);
    return word;
  }).join('');

  return addBastardilla ? `${formattedBastardilla}${result}${formattedBastardilla}` : result;
};

export { toBraille, binaryToBraille };
