import '../styles/Root.scss';
import { Editor } from '../layouts/Editor'

const Translator = () => {
  return (
    <main className='main is-small-padding'>
      <Editor />
    </main>
  );
}

export default Translator;
