import { Outlet } from 'react-router-dom';

import { Navbar } from '../components/Navbar';

const navItems = [
  { label: 'Texto a Braille', to: '/' },
  { label: 'Maquina de Escribir', to: '/braille-typewriter' },
  { label: 'Matemáticas', to: '/under-construction' }
];

const HeaderFooterWrapper = () => {
  return (
    <>
      <header>
        <Navbar navItems={navItems} />
      </header>
      <Outlet />
    </>
  )
};

export default HeaderFooterWrapper;
