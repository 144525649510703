import { useState, forwardRef, useImperativeHandle } from 'react';

const ExportModal = forwardRef(({ getSunEditorContent }, ref) => {
  const [isActive, setActive] = useState(false);
  const [fileName, setFileName] = useState('');

  const activeClassName = isActive ? ' is-active' : '';

  const open = () => setActive(true);
  const close = () => {setFileName(''); setActive(false)};

  useImperativeHandle(ref, () => ({ open, close }));

  const handleNameChange = (e) => setFileName(e.target.value);
  const handleConfirm = () => { 
    const content = getSunEditorContent();
    const blob = new Blob([content], { type: 'text/xml' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${fileName || (new Date()).toLocaleString()}.xml`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    close(); 
  }

  return (
    <div className={`modal${activeClassName}`}>
      <div className='modal-background' onClick={close} />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Exportar Contenido</p>
          <button className='delete' aria-label='close' onClick={close} />
        </header>
        <section className='modal-card-body'>
          <div className='field has-addons'>
            <div className='control is-expanded'>
              <input 
                className='input'
                type='text'
                placeholder='Nombre del Archivo'
                value={fileName}
                onChange={handleNameChange}
              />
            </div>
            <div className='control'>
              <button className='button is-static'>.xml</button>
            </div>
          </div>
        </section>
        <footer className='modal-card-foot'>
          <div className='buttons'>
            <button className='button is-success' onClick={handleConfirm}>Confirmar</button>
            <button className='button' onClick={close}>Cancelar</button>
          </div>
        </footer>
      </div>
    </div>
  );
});

export { ExportModal };
