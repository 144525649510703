import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Navbar = ({navItems = [], showEnd = false}) => {
  const { pathname } = useLocation();
  const [isBurgerActive, setBurgerActive] = useState(false);

  const toogleBurger = () => setBurgerActive(prev => !prev);
  const activeClassName = isBurgerActive ? ' is-active' : '';

  return (
    <nav className='navbar is-info' role='navigation' aria-label='main navigation'>
      <div className='navbar-brand'>
        <NavLink className='navbar-item' to='/'>
          <img src='/logo192.png' alt='logo'></img>
        </NavLink>

        <NavLink role='button' 
          className={`navbar-burger burger ${activeClassName}`}
          aria-label='menu'
          aria-expanded='false'
          data-target='navbarMain'
          onClick={toogleBurger}
        >
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </NavLink>
      </div>

      <div id='navbarMain' className={`navbar-menu ${activeClassName}`}>
        <div className='navbar-start'>
          {navItems.map(({label, to}) => <NavLink key={label} to={to} className={`navbar-item${pathname === to ? ' is-active' : ''}`}>{label}</NavLink>)}
        </div>

        {showEnd && <div className='navbar-end'>
          <div className='navbar-item'>
            <div className='buttons'>
              <NavLink className='button is-info is-light'>
                Cerrar sesión
              </NavLink>
            </div>
          </div>
        </div>}
      </div>
    </nav>
  );
}

export { Navbar };
