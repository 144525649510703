// 6  3
// 5  2
// 4  1

const characterTranslator = {
  // simple punctuation
  '"': [0b100110],
  ',': [0b000010],
  '.': [0b000100],
  '?': [0b100010],
  '!': [0b010110],
  ';': [0b000110],
  ':': [0b010010],
  '-': [0b100100],

  // directional punctuation
  '(': [0b100011],
  ')': [0b011100],
  '[': [0b110111],
  ']': [0b111110],
  '{': [0b010000, 0b000111],
  '}': [0b111000, 0b010000],
  '<': [0b101010],
  '>': [0b010101],
  '/': [0b100000, 0b000010],
  '\\': [0b010000, 0b000100],

  // weirder punctuation
  //'~': [0b001000, 0b010100],
  '@': [0b001000],
  '#': [0b111000, 0b111001],//*
  '$': [0b111000, 0b001110],
  '%': [0b111000, 0b110100],
  //'^': [0b001000, 0b100010],
  //'&': [0b001000, 0b101111],
  '*': [0b010000, 0b010100],
  '_': [0b100100, 0b100100],
  '=': [0b110110],
  '+': [0b010000, 0b010110],
  //'|': [0b111000, 0b110011],
  //'`': [0b101000, 0b100001],

  // Grados, minutos y segundos
  '°': [0b110100],
  '′': [0b110011],
  '″': [0b110011, 0b110011],

  // digits
  '0': [0b011010], //⠚
  '1': [0b000001], //⠁
  '2': [0b000011], //⠃
  '3': [0b001001], //⠉
  '4': [0b011001], //⠙
  '5': [0b010001], //⠑
  '6': [0b001011], //⠋
  '7': [0b011011], //⠛
  '8': [0b010011], //⠓
  '9': [0b001010]  //⠊
};

// first 10 letters
characterTranslator['a'] = characterTranslator['1'];
characterTranslator['b'] = characterTranslator['2'];
characterTranslator['c'] = characterTranslator['3'];
characterTranslator['d'] = characterTranslator['4'];
characterTranslator['e'] = characterTranslator['5'];
characterTranslator['f'] = characterTranslator['6'];
characterTranslator['g'] = characterTranslator['7'];
characterTranslator['h'] = characterTranslator['8'];
characterTranslator['i'] = characterTranslator['9'];
characterTranslator['j'] = characterTranslator['0'];

// next 10 letters
characterTranslator['k'] = [characterTranslator['a'][0] | 0b000100];
characterTranslator['l'] = [characterTranslator['b'][0] | 0b000100];
characterTranslator['m'] = [characterTranslator['c'][0] | 0b000100];
characterTranslator['n'] = [characterTranslator['d'][0] | 0b000100];
characterTranslator['o'] = [characterTranslator['e'][0] | 0b000100];
characterTranslator['p'] = [characterTranslator['f'][0] | 0b000100];
characterTranslator['q'] = [characterTranslator['g'][0] | 0b000100];
characterTranslator['r'] = [characterTranslator['h'][0] | 0b000100];
characterTranslator['s'] = [characterTranslator['i'][0] | 0b000100];
characterTranslator['t'] = [characterTranslator['j'][0] | 0b000100];

// next 5 letters (excluding w)
characterTranslator['u'] = [characterTranslator['a'][0] | 0b100100];
characterTranslator['v'] = [characterTranslator['b'][0] | 0b100100];
characterTranslator['x'] = [characterTranslator['c'][0] | 0b100100];
characterTranslator['y'] = [characterTranslator['d'][0] | 0b100100];
characterTranslator['z'] = [characterTranslator['e'][0] | 0b100100];

// w is not part of the original braille (due to not being a french letter)
characterTranslator['w'] = [0b111010];

// ñ is an special character for spanish
characterTranslator['ñ'] = [characterTranslator['n'][0] | 0b000011];

// accented vowels
characterTranslator['á'] = [0b110111];
characterTranslator['é'] = [0b101110];
characterTranslator['í'] = [0b001100];
characterTranslator['ó'] = [0b101100];
characterTranslator['ú'] = [0b111110];

// special characters
characterTranslator['¿'] = characterTranslator['?'];
characterTranslator['¡'] = characterTranslator['!'];
characterTranslator["'"] = characterTranslator['.'];

export default characterTranslator;
